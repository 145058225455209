import React, { useState, useEffect } from 'react'
import Img from "gatsby-image"
import serialize from 'form-serialize';
import axios from "axios"
import { Link } from "gatsby"




const ContactPromo = (props) => {
    const data = props.data


    const [emailResponse, setEmailResponse] = useState("");

    const [loading, setLoading] = useState(false);

    // const [selectedPacket, setSelectedPacket] = useState(props.selectedPacket);

    const [termsCheckbox, setTermsCheckbox] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");


    function contactMessage(e) {
        e.preventDefault();
        if (!loading) {
            setLoading(true)
            let form = document.querySelector('#contactForm');
            let str = serialize(form, { hash: true });

            str.originator = props.originator;

            str.terms = termsCheckbox


            if (str.terms) {
                if (str.name && str.email && str.phone_number) {
                    setErrorMessage("")
                    axios.post(`https://admin.w3vitals.com/wp-json/myplugin/v1/promoEmail`, str)
                        .then(function (response) {
                            setEmailResponse(response.data)
                            setLoading(false)

                            window.dataLayer = window.dataLayer || [];

                            window.dataLayer.push({
                                'event': 'eshop_form_submission_landingpage'
                            });

                            // if (props.originator === "Εspa-Full") {
                            //     window.dataLayer.push({
                            //         'event': 'eshop_form_submission_approved'
                            //     });
                            // } else {
                            //     window.dataLayer.push({
                            //         'event': 'eshop_form_submission'
                            //     });
                            // }

                        })
                        .catch(function (error) {
                            // handle error
                            setLoading(false)
                        })
                } else {
                    setErrorMessage("Παρακαλούμε συμπληρώστε όλα τα απαραίτητα πεδία")
                    setLoading(false)
                }
            } else {
                setErrorMessage("Παρακαλούμε αποδεχτείτε τους Όρους Χρήσης και την Πολιτική Απορρήτου")
                setLoading(false)
            }


        }
    }


    return (
        <section className="es-contact-area ptb-100" id="interest-form" style={{ position: "relative" }}>
            <div className="container">
                {emailResponse === "" && (
                    <div className="section-title">
                        {/* <span className="sub-title" style={{color: "#DB3A00"}}>
                        Επικοινωνία
                    </span> */}
                        <h2 className='es-h2'> Εκδήλωση Ενδιαφέροντος</h2>
                        <p className='es-main-p' style={{marginTop: "1rem"}}>Συμπληρώστε την παρακάτω φόρμα και θα επικοινωνήσουμε μαζί σας για μια αξιολόγηση των αναγκών σας</p>
                    </div>
                )}

                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-lg-6 col-md-12">
                        {emailResponse === "" && (
                            <div className="es-contact-form">
                                <form id="contactForm" onSubmit={(e) => contactMessage(e)}>
                                    <div className="row">
                                        {/* <div key={props.selectedPacket} className="col-lg-12 col-md-12" style={{ marginTop: "5px", marginBottom: "1rem" }}>
                                            <div className="form-group">
                                                <label style={{ color: "#fff", fontWeight: "700" }} for="form-packet">Το πακέτο που σας ενδιαφέρει</label>
                                                <select className="select-css" value={props.selectedPacket} id="form-packet" onChange={(e) => props.selectPacket(e.target.value)}>
                                                    {props.originator === "Εspa-Full"
                                                        ?
                                                        <>
                                                            <option value="STANDARD">STANDARD</option>
                                                            <option value="PRO">PRO</option>
                                                            <option value="ADVANCED">ADVANCED</option>
                                                            <option value="ENTERPRISE">ENTERPRISE</option>
                                                        </>
                                                        :
                                                        <>
                                                            <option value="Standard">STANDARD</option>
                                                            <option value="Pro">PRO</option>
                                                            <option value="Advanced">ADVANCED</option>
                                                            <option value="Enterprise">ENTERPRISE</option>
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                        </div> */}

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label style={{ color: "#fff", fontWeight: "700" }} for="form-name">Ονοματεπώνυμο*</label>
                                                <input id="form-name" type="text" name="name" className="form-control" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label style={{ color: "#fff", fontWeight: "700" }} for="form-email">Email*</label>
                                                <input id="form-email" type="email" name="email" className="form-control" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label style={{ color: "#fff", fontWeight: "700" }} for="form-phone">Τηλέφωνο*</label>
                                                <input id="form-phone" type="text" name="phone_number" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12" style={{ marginTop: "5px", marginBottom: "1rem", color: "rgb(117, 117, 117)" }}>
                                            <label for="form-aggr">
                                                <input
                                                    name="terms"
                                                    type="checkbox"
                                                    checked={termsCheckbox}
                                                    id={"form-aggr"}
                                                    onChange={() => setTermsCheckbox(!termsCheckbox)}
                                                />
                                                <span style={{ paddingLeft: "5px", color: "#fff" }}>
                                                    Αποδέχομαι τους <a href="https://w3vitals.com/oroi-kai-proipotheseis" style={{ color: "#DB3A00" }} target="_blank" >Όρους Χρήσης</a> και την <a href="https://w3vitals.com/politiki-aporritou" style={{ color: "#DB3A00" }} target="_blank">Πολιτική Απορρήτου</a>
                                                </span>
                                            </label>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn">
                                                {!loading ?
                                                    <>
                                                        <span style={{ position: "absolute", left: "20px" }}>✓</span>
                                                        Αποστολή <span></span>
                                                    </>
                                                    :
                                                    <>
                                                        <div class="loadingio-spinner-eclipse-gdyi2n0ixw"><div class="ldio-tfhfcwjz1ds">
                                                            <div></div>
                                                        </div></div>
                                                    Αναμονή
                                                    </>
                                                }
                                            </button>
                                        </div>

                                        <div style={{ color: "#ef3c2e", paddingTop: "15px" }}>{errorMessage}</div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {emailResponse !== "" && (
                            <div className='cp-es-mssg' style={{ display: "flex", alignItems: "center" }}>
                                <div dangerouslySetInnerHTML={{ __html: emailResponse }}></div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
            <div className="circle-shape1">
                <Img fixed={props.circleShape1.childImageSharp.fixed} alt="banner" />
            </div>
        </section>
    )
}

export default ContactPromo